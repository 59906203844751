import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { defaultGenericState, GenericStateType } from "store/types";
import { fetchCientConfig } from "./config-action";
import { Configuration, createDefaultConfig } from "./configuration";

export interface ConfigurationState extends Configuration, GenericStateType {}

export const configSlice = createSlice({
  name: "config",
  initialState: {
    ...defaultGenericState,
    ...createDefaultConfig(null, null),
  } as ConfigurationState,
  reducers: {
    updateConfig(state, action: PayloadAction<Configuration>) {
      state.client = action.payload.client;
      state.paymentCenter = action.payload.paymentCenter;
    },
    resetConfig(state) {
      const defaultConfig = createDefaultConfig(null, null);
      state.client = defaultConfig.client;
      state.paymentCenter = defaultConfig.paymentCenter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCientConfig.fulfilled,
      (state, action: PayloadAction<Configuration>) => {
        state.client = action.payload.client;
        state.paymentCenter = action.payload.paymentCenter;
        state.isLoading = false;
        state.error = false;
      }
    );
    builder.addCase(
      fetchCientConfig.rejected,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = true;
      }
    );
    builder.addCase(fetchCientConfig.pending, (state) => {
      state.isLoading = true;
    });
  },
});

// export const selectConfig = (state: RootState) => state.config;

export const { updateConfig, resetConfig } = configSlice.actions;

export default configSlice.reducer;
