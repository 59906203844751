import { AxiosInstance } from "axios";

import * as requestUrl from "../../constants/request";

import { Configuration, createDefaultConfig } from "./configuration";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCientConfig = createAsyncThunk(
  "config/fetchCientConfig",
  async ({ request, key }: { request: AxiosInstance; key: string }) => {
    const [clientConfigResponse, paymentCenterConfigResponse] =
      await Promise.all([
        request(requestUrl.FT_CLIENT_CONFIG),
        request(requestUrl.FT_PAYMENT_CENTER_CONFIG),
      ]);

    const defaultConfig = createDefaultConfig(key, null);
    const config: Configuration = {
      ...defaultConfig,
      client: {
        ...defaultConfig.client,
        ...clientConfigResponse.data,
      },
      paymentCenter: {
        ...defaultConfig.paymentCenter,
        ...paymentCenterConfigResponse.data,
      },
    };
    if (config.client.idPId) {
      localStorage.setItem(key, config.client.idPId);
      localStorage.setItem("lastSavedKey", key);
    }

    return config;
  }
);
